import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Hero from '../components/hero'
import heroimg from '../../static/images/contents/404.png'
import { StaticQuery, graphql } from 'gatsby'

const datahero = {
  "title": "Ooops... <br/> la pagina che stai cercando non si trova, forse l'hanno nascosta loro",
  "subtitle": "",
  "imgstatic": heroimg,
  "arealink": false 
};

const NotFoundPage = () => (
  <Layout>
    <Helmet
      title="Pagina non trovata"
      bodyAttributes={
        { class :'has-hero'}
      }
    />
    <StaticQuery
    query={graphql`
      query NotFoundQuery {
        heroimg: file(name: {eq: "404"}) {
          childImageSharp {
            fluid(maxWidth:1940) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }  
      }
    `}
    render={data => (
      <Hero data={datahero} cover={data.heroimg}/>
    )}/>
    <div className="container">
      <div className="section section--m-top">
        <div className="row">
          <div className="col-12 text-center">
            <p>
              <div><a href="/">Torna alla home</a></div>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
